<template>
    <div class="relative container m-auto border border-red-200 h-screen overflow-scroll p-5 mt-5">
        <p class="text-center mb-5">Click to copy icon template !!</p>
        <div class="flex gap-4 flex-wrap" >
            <div
                :title="comp"
                @click="copyIcon(comp)" 
                v-for="(comp, ind) in components" 
                :key="ind" 
                class="bg-secondary-five p-8 flex justify-between items-center rounded cursor-pointer opacity-75 hover:opacity-100 transition duration-150"
            >
                <component 
                    :is="comp"
                    class="w-8 h-6"
                >
                </component>
            </div>
        </div>
        <div v-if="show" class="transition duration-150 text-white bg-gray-600 rounded px-5 py-2 animate-bounce absolute top-0 right-0">Copy Success !!</div>
    </div>
</template>

<script>
import Vue from "vue";
export default {
    name: 'IconsPreview',
    data: () => ({
        components: [],
        show: false,
    }),
    methods: {
        copyIcon(name) {
            navigator.clipboard.writeText(`<${name} class="w-5 h-5" />`);
            this.toggleShow()
        },
        toggleShow() {
            this.show = true
            setTimeout(() => {this.show = false}, 1000)
        }
    },
    mounted() {
        const ComponentContext = require.context('@/elements/icons', true, /\.vue$/i, 'lazy');
    //   const ComponentContext = require.context('@/elements/icons', true, /\.vue$/i, 'lazy');
        ComponentContext.keys().forEach((componentFilePath) => {
            const componentName = componentFilePath.split('/').pop().split('.')[0];
            this.components.push(componentName)
            //Vue.component(componentName, () => import(`@/elements/icons/${componentName}`));
        });
    }
}
</script>